import React from "react";
import { Link } from "react-router-dom";
import pickup from "../../../assets/images/pickup-2.png";

const CityHero = (props) => {
  return (
    <div className="hero--4 ">
      <div className="hero--cover-3 flex items-center  h-full px-3 py-12 sm:py-16 bg-sec-100">
        <div className="container">
          <div className="grid md:grid-cols-2  gap-10">
            <div className="text-white my-auto mx-auto text-center md:text-left">
              <h3 className="text-white">
                <Link
                  to="/home"
                  className="text-white hover:text-pri-500 font-semibold"
                >
                  Home
                </Link>{" "}
                / Cities
              </h3>
              <h2 className="text-white">{props.hero.title}</h2>
              <div className="-mt-2 mb-5 text-base max-w-xl">
                {props.hero.description}
              </div>
              <Link
                to="/book-now-a-chauffeur-service"
                className="btn2   text-base  cursor-pointer"
              >
                Book now
              </Link>
            </div>
            <div className="hidden md:flex items-center justify-center ">
              <img className="h-80" src={pickup} alt="flight" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CityHero;
