import React from "react";
import CityDesc from "../../components/web/cities/CityDesc";
import CityHero from "../../components/web/cities/CityHero";
import Stay from "../../components/web/Stay";
import MostBooked from "../../components/web/MostBooked";
import Faq from "../../components/web/Faq";
import Banner1 from "../../components/web/banners/Banner1";
import { Helmet } from "react-helmet-async";

import one1 from "../../assets/images/hotels/cities/whitby/holiday-inn-express-whitby-chauffeur-service-whitby-limo-services-car-service-near-whitby.jfif";
import one2 from "../../assets/images/hotels/cities/whitby/holiday-inn-express-whitby-private-chauffeur-service-whitby-car-service-from-whitby.jfif";
import one3 from "../../assets/images/hotels/cities/whitby/airport-transfer-services-from-holiday-inn-express-whitby-best-price-car-services-in-whitby.jfif";
import two1 from "../../assets/images/hotels/cities/whitby/limousine-services-from-residence-inn-by-marriott-whitby-car-service-24-hours-in-whitby-best-car-services-in-whitby.webp";
import two2 from "../../assets/images/hotels/cities/whitby/chauffeur-service-in-whitby-to-residence-inn-by-marriott-whitby-at-affordable-price-limo-car-service-downtown-whitby.webp";
import two3 from "../../assets/images/hotels/cities/whitby/hourly-chauffeur-service-from-residence-inn-by-marriott-whitby-affordable-car-services-near-whitby.webp";
import three1 from "../../assets/images/hotels/cities/whitby/car-service-from-whitby-to-hilton-garden-inn-toronto-ajax-all-the-way-limo-services-whitby.jpg";
import three2 from "../../assets/images/hotels/cities/whitby/executive-chauffeur-service-near-hilton-garden-inn-toronto-ajax-comfortable-chauffeur-services-in-whitby.jpg";
import three3 from "../../assets/images/hotels/cities/whitby/budget-car-hire-whitby-to-hilton-garden-inn-toronto-ajax-toronto-affordable-price-luxury-service-limousines.jpg";
import four1 from "../../assets/images/hotels/cities/whitby/car-hire-near-homewood-suites-by-hilton-ajax-business-class-limo-services.jpg";
import four2 from "../../assets/images/hotels/cities/whitby/all-inclusive-limo-services-near-homewood-suites-by-hilton-ajax-limo-services-online-bookings.jpg";
import four3 from "../../assets/images/hotels/cities/whitby/whitby-limo-services-to-homewood-suites-by-hilton-ajax-car-service-with-driver.jpg";

const Whitby = () => {
  const hero = {
    title: "Whitby",
    description:
      "Experience Achauffeur's first-rate, premium and luxury limousine service in Whitby city. We meticulously maintain our fleet of vehicles to guarantee secure and comfortable journeys.",
  };

  const desc = {
    title: "Whitby",
    description:
      "Whitby is a town located in the Regional Municipality of Durham in southern Ontario, Canada, about 30 kilometers (20 miles) east of Toronto. It is the largest town in the region, with a population of over 128,000 people. Whitby is known for its strong economy, with a number of major industries located in the region, including manufacturing, healthcare, and education. The town is home to a number of cultural attractions, including the Whitby Public Library, the Whitby Central Arena, and the Whitby Courthouse Theatre. In addition to its economic and cultural strengths, Whitby is also home to a number of universities and colleges, including Durham College and the University of Ontario Institute of Technology, making it a center of higher education and research.",
    website:
      "https://www.whitby.ca/en/play/parks-trails-and-being-active-outdoors.aspx",
    description2:
      "Achauffeur is a professional transportation service provider in Whitby. we have a trained and licensed driver who drives a passenger in a luxury vehicle, such as a limousine. The chauffeur is responsible for navigating the vehicle to the desired destination, handling any necessary vehicle maintenance and repairs, and providing a high level of customer service to the passenger. we always focus on the safe and timely transportation of the passengers, as well as maintaining the cleanliness and upkeep of the vehicle. our services are often used for airport transfers, special events, business meetings, meet and greet, parcel delivery and etc, and can be hired on an hourly or daily basis via an online reservation system.",
  };
  const stays = [
    {
      image: [one1, one2, one3],
      alt: [
        "Book limo services from whitby to The Holiday Inn Express Whitby  best car services from achauffeur",
        "Private chauffeur service from whitby city to Holiday Inn Express Whitby . luxury car service near whitby",
        "Find airport transfer services in whitby to Holiday Inn Express Whitby  at an affordable price and best services",
      ],
      title: "Holiday Inn Express Whitby Oshawa",
      location: "Whitby, Ontario",
      distance: "4.4 km from center",
      map: "https://maps.google.com/maps?q=Holiday%20Inn%20Express%20Whitby%20Oshawa&t=&z=13&ie=UTF8&iwloc=&output=embed",
    },
    {
      image: [two1, two2, two3],
      alt: [
        "Professional limousine services from Residence Inn by Marriott Whitby  to whitby city. Best car services from achauffeur",
        "Safe and secure chauffeur services in whitby to Residence Inn by Marriott Whitby . All-inclusive car services downtown whitby",
        "Hourly chauffeur service from achauffeur to Residence Inn by Marriott Whitby  from whitby canada at affordable price rates",
      ],
      title: "Residence Inn by Marriott Whitby",
      location: "Whitby, Ontario",
      distance: "4.4 km from center",
      map: "https://maps.google.com/maps?q=Residence%20Inn%20by%20Marriott%20Whitby&t=&z=13&ie=UTF8&iwloc=&output=embed",
    },
    {
      image: [three1, three2, three3],
      alt: [
        "All the way limo services from whitby to Hilton Garden Inn Toronto/Ajax . Achauffeur Minimize your Stress and Frustration ",
        "Comfortable and executive chauffeur service to Hilton Garden Inn Toronto/Ajax  from whitby city. reliable  booking process from achauffeur",
        "achauffeur offers a budget car service in whitby. Hire budget cars and limos to Hilton Garden Inn Toronto/Ajax . Luxury service available",
      ],
      title: "Hilton Garden Inn Toronto/Ajax",
      location: "Whitby, Ontario",
      distance: "9.6 km from center",
      map: "https://maps.google.com/maps?q=Hilton%20Garden%20Inn%20Toronto/Ajax&t=&z=13&ie=UTF8&iwloc=&output=embed",
    },
    {
      image: [four1, four2, four3],
      alt: [
        "Business class chauffeur services in whitby to Homewood Suites by Hilton Ajax . Luxury cars at affordable price",
        "Book your limousines online. All-inclusive limo services from whitby city to Homewood Suites by Hilton Ajax ",
        "Do you need a car with driver? Achauffeur offers limo services with driver in whitby to Homewood Suites by Hilton Ajax ",
      ],
      title: "Homewood Suites by Hilton Ajax ",
      location: "Whitby, Ontario",
      distance: "9.7 km from center",
      map: "https://maps.google.com/maps?q=Homewood%20Suites%20by%20Hilton%20Ajax&t=&z=13&ie=UTF8&iwloc=&output=embed",
    },
  ];
  const mostBookeds = [
    {
      start: "Whitby",
      end: "Toronto, Ontario",
      duration: "43  min",
      distance: "55.6 Km",
      price: "211.72",
    },
    {
      start: "Whitby",
      end: "Orillia, Ontario",
      duration: "1 hr 21 min",
      distance: "101 Km",
      price: "211.72",
    },
    {
      start: "Whitby",
      end: "Markham, Ontario",
      duration: "31  min",
      distance: "41.9 Km",
      price: "211.72",
    },
    {
      start: "Whitby",
      end: "Niagara Falls, Ontario",
      duration: "1 hr 51 min",
      distance: "181 Km",
      price: "211.72",
    },
    {
      start: "Whitby",
      end: "Mississauga, Ontario",
      duration: "54  min",
      distance: "76.7 Km",
      price: "211.72",
    },
    {
      start: "Whitby",
      end: "Hamilton, Ontario",
      duration: "1 hr 15 min",
      distance: "121 Km",
      price: "211.72",
    },
  ];
  const faqs = [
    {
      header: "Is my booking a shared shuttle or a private?",
      key: "1",
      answer: " All the bookings are private.",
    },
    {
      header: "How to book?",
      key: "2",
      answer:
        "You can make a reservation by filling the form on our website, giving          us a call at (888) 999-4545 or (416) 800-1009, or sending us an email.",
    },
    {
      header: "How can I contact my driver?",
      key: "3",
      answer:
        "If you need to contact your chauffeur, the best number to call will be          listed in the SMS alert while your booking confirmation. You can          always call us at (888) 999-4545 or (416) 800-1009.",
    },
    {
      header: "What factors influence the price?",
      key: "4",
      answer:
        "There are many factors that influence the cost, but the distance traveled and the type of vehicle are two of the major elements that affect the cost of travel. Depending on whether you are scheduling an hourly reservation or a one-way trip, rates are based on the distance and duration. Pickup time, Meet & Greet service at airports, and how far in advance the ride was scheduled are additional elements that affect our charges. The chauffeur's gratuity, any tolls, parking fees, baggage fees, additional guest fees, fares, taxes, and any other related costs are all included in all of our rates.",
    },
    {
      header: "  If I have more questions, who should I contact?",
      key: "5",
      answer:
        " If you have any more questions, feel free to reach us through           whatsapp, call, message or email . Our customer service is available          at all times of the day and will happily attend to your needs.",
    },
  ];
  return (
    <>
      <Helmet>
        <title>Pre-Book an all-inclusive chauffeur service in Whitby</title>
        <meta
          name="description"
          content="Your Whitby Private Chauffeur Service. Pre-Book Professional all-inclusive, reliable and efficient chauffeur services in Whitby. Best Price Guaranteed."
        />
        <meta
          name="keywords"
          content="Chauffeur service in Whitby, Chauffeur service from Whitby, chauffeur service from toronto to Whitby, chauffeur service from Whitby, chauffeur service to Whitby, Chauffeur service, airport chauffeur Service,Whitby chauffeur service, chauffeur service Whitby, private chauffeur service Whitby, A Chauffeur, Airport chauffeur service Whitby, Chauffeur service near me, Airport Chauffeur, Private car service, Airport car service, airport limo Whitby, Shuttle service Whitby, Hourly chauffeur service, Livery service"
        />
        <link rel="canonical" href="/chauffeur-service-whitby" />
      </Helmet>
      <CityHero hero={hero} />
      <CityDesc desc={desc} />
      <MostBooked mostBookeds={mostBookeds} />
      <Stay stays={stays} />
      <Banner1 />
      <Faq faqs={faqs} />
    </>
  );
};

export default Whitby;
