import React from "react";
import CityDesc from "../../components/web/cities/CityDesc";
import CityHero from "../../components/web/cities/CityHero";
import Stay from "../../components/web/Stay";
import MostBooked from "../../components/web/MostBooked";
import Faq from "../../components/web/Faq";
import Banner1 from "../../components/web/banners/Banner2";
import { Helmet } from "react-helmet-async";

import one1 from "../../assets/images/hotels/cities/peterborough/peterborough-inn-and-suites-hotel-chauffeur-service-peterborough-limo-services-car-service-near-peterborough.jpg";
import one2 from "../../assets/images/hotels/cities/peterborough/peterborough-inn-and-suites-hotel-private-chauffeur-service-peterborough-car-service-from-peterborough.jpg";
import one3 from "../../assets/images/hotels/cities/peterborough/airport-transfer-services-from-peterborough-inn-and-suites-hotel-best-price-car-services-in-peterborough.jpg";
import two1 from "../../assets/images/hotels/cities/peterborough/limousine-services-from-holiday-inn-peterborough-waterfront-car-service-24-hours-in-peterborough-best-car-services-in-peterborough.png";
import two2 from "../../assets/images/hotels/cities/peterborough/chauffeur-service-in-peterborough-to-holiday-inn-peterborough-waterfront-at-affordable-price-limo-car-service-downtown-peterborough.jfif";
import two3 from "../../assets/images/hotels/cities/peterborough/hourly-chauffeur-service-from-holiday-inn-peterborough-waterfront-affordable-car-services-near-peterborough.jfif";
import three1 from "../../assets/images/hotels/cities/peterborough/car-service-from-toronto-to-super-8-by-wyndham-peterborough-all-the-way-limo-services-peterborough.webp";
import three2 from "../../assets/images/hotels/cities/peterborough/executive-chauffeur-service-near-super-8-by-wyndham-peterborough-comfortable-chauffeur-services-in-peterborough.webp";
import three3 from "../../assets/images/hotels/cities/peterborough/budget-car-hire-peterborough-to-super-8-by-wyndham-peterborough-affordable-price-luxury-service-limousines.webp";
import four1 from "../../assets/images/hotels/cities/peterborough/car-hire-near-hampton-inn-by-hilton-peterborough-business-class-limo-services.jpg";
import four2 from "../../assets/images/hotels/cities/peterborough/all-inclusive-limo-services-near-hampton-inn-by-hilton-peterborough-limo-services-online-bookings.jpg";
import four3 from "../../assets/images/hotels/cities/peterborough/peterborough-limo-services-to-fhampton-inn-by-hilton-peterborough-car-service-with-driver.jpg";

const Peterborough = () => {
  const hero = {
    title: "Peterborough",
    description:
      "Have a great comfortable journey to and from Peterborough city with our first-class limos. Transfers for both business and entertainment are available with our Chauffeur Service in Peterborough. Our chauffeur service provides private, personal, limo, executive car services, and so on.",
  };

  const desc = {
    title: "Peterborough",
    description:
      "Peterborough is a city located in central Ontario, Canada, about 100 kilometers (60 miles) northeast of Toronto. It is the 11th largest city in Ontario, with a population of over 121,000 people. Peterborough is known for its strong economy, with a number of major industries located in the region, including manufacturing, healthcare, and education. The city is home to a number of cultural attractions, including the Peterborough Museum and Archives, the Peterborough Art Gallery, and the Peterborough Lift Lock National Historic Site. In addition to its economic and cultural strengths, Peterborough is also home to a number of universities and colleges, including Trent University and Fleming College, making it a center of higher education and research.",
    website:
      "https://www.peterborough.ca/en/explore-and-play/explore-and-play.aspx",
    description2:
      "Achauffeur is a professional transportation service provider in Peterborough. we have a trained and licensed driver who drives a passenger in a luxury vehicle, such as a limousine. The chauffeur is responsible for navigating the vehicle to the desired destination, handling any necessary vehicle maintenance and repairs, and providing a high level of customer service to the passenger. we always focus on the safe and timely transportation of the passengers, as well as maintaining the cleanliness and upkeep of the vehicle. our services are often used for airport transfers, special events, business meetings, meet and greet, parcel delivery and etc, and can be hired on an hourly or daily basis via an online reservation system.",
  };
  const stays = [
    {
      image: [one1, one2, one3],
      alt: [
        "Book limo services from peterborough to Peterborough Inn & Suites Hotel  best car services from achauffeur",
        "Private chauffeur service from peterborough city to Peterborough Inn & Suites Hotel . luxury car service near peterborough",
        "Find airport transfer services in peterborough to Peterborough Inn & Suites Hotel  at an affordable price and best services",
      ],
      title: "Peterborough Inn & Suites Hotel",
      location: "Peterborough, Ontario",
      distance: "180 m from center",
      map: "https://maps.google.com/maps?q=Peterborough%20Inn%20and%20Suites%20Hotel&t=&z=13&ie=UTF8&iwloc=&output=embed",
    },
    {
      image: [two1, two2, two3],
      alt: [
        "Professional limousine services from Holiday Inn Peterborough-Waterfront to peterborough city. Best car services from achauffeur",
        "Safe and secure chauffeur services in peterborough to Holiday Inn Peterborough-Waterfront. All-inclusive car services downtown peterborough ",
        "Hourly chauffeur service from achauffeur to Holiday Inn Peterborough-Waterfront from peterborough canada at affordable price rates",
      ],
      title: "Holiday Inn Peterborough-Waterfront",
      location: "Peterborough, Ontario",
      distance: "750 m from center",
      map: "https://maps.google.com/maps?q=Holiday%20Inn%20Peterborough-Waterfront&t=&z=13&ie=UTF8&iwloc=&output=embed",
    },
    {
      image: [three1, three2, three3],
      alt: [
        "All the way limo services from peterborough to Super 8 by Wyndham Peterborough . Achauffeur Minimize your Stress and Frustration ",
        "Comfortable and executive chauffeur service to Super 8 by Wyndham Peterborough  from peterborough city. reliable  booking process from achauffeur",
        "achauffeur offers a budget car service in peterborough. Hire budget cars and limos to Super 8 by Wyndham Peterborough . Luxury service available",
      ],
      title: "Super 8 by Wyndham Peterborough",
      location: "Peterborough, Ontario",
      distance: "4.8 km from center",
      map: "https://maps.google.com/maps?q=Super%208%20by%20Wyndham%20Peterborough&t=&z=13&ie=UTF8&iwloc=&output=embed",
    },
    {
      image: [four1, four2, four3],
      alt: [
        "Business class chauffeur services in peterborough to Hampton Inn by Hilton Peterborough . Luxury cars at affordable price",
        "Book your limousines online. All-inclusive limo services from peterborough city to Hampton Inn by Hilton Peterborough ",
        "Do you need a car with driver? Achauffeur offers limo services with driver in peterborough to Hampton Inn by Hilton Peterborough ",
      ],
      title: "Hampton Inn by Hilton Peterborough",
      location: "Peterborough, Ontario",
      distance: "5.8 km from center",
      map: "https://maps.google.com/maps?q=Hampton%20Inn%20by%20Hilton%20Peterborough&t=&z=13&ie=UTF8&iwloc=&output=embed",
    },
  ];
  const mostBookeds = [
    {
      start: "Peterborough",
      end: "Toronto, Ontario",
      duration: "1 hr 28 min",
      distance: "139 Km",
      price: "211.72",
    },
    {
      start: "Peterborough",
      end: "Ottawa, Ontario",
      duration: "3 hr 6 min",
      distance: "271 Km",
      price: "211.72",
    },
    {
      start: "Peterborough",
      end: "Kingston, Ontario",
      duration: "2 hr 7 min",
      distance: "181 Km",
      price: "211.72",
    },
    {
      start: "Peterborough",
      end: "Prince Edward, Ontario",
      duration: "1h 33 min",
      distance: "121 Km",
      price: "211.72",
    },
    {
      start: "Peterborough",
      end: "Montreal, Quebec",
      duration: "4 hr 57 min",
      distance: "460 Km",
      price: "211.72",
    },
    {
      start: "Peterborough",
      end: "Oshawa, Ontario",
      duration: "53  min",
      distance: "75.2 Km",
      price: "211.72",
    },
  ];
  const faqs = [
    {
      header: "Is my booking a shared shuttle or a private?",
      key: "1",
      answer: " All the bookings are private.",
    },
    {
      header: "How to book?",
      key: "2",
      answer:
        "You can make a reservation by filling the form on our website, giving          us a call at (888) 999-4545 or (416) 800-1009, or sending us an email.",
    },
    {
      header: "How can I contact my driver?",
      key: "3",
      answer:
        "If you need to contact your chauffeur, the best number to call will be          listed in the SMS alert while your booking confirmation. You can          always call us at (888) 999-4545 or (416) 800-1009.",
    },
    {
      header: "What factors influence the price?",
      key: "4",
      answer:
        "There are many factors that influence the cost, but the distance traveled and the type of vehicle are two of the major elements that affect the cost of travel. Depending on whether you are scheduling an hourly reservation or a one-way trip, rates are based on the distance and duration. Pickup time, Meet & Greet service at airports, and how far in advance the ride was scheduled are additional elements that affect our charges. The chauffeur's gratuity, any tolls, parking fees, baggage fees, additional guest fees, fares, taxes, and any other related costs are all included in all of our rates.",
    },
    {
      header: "  If I have more questions, who should I contact?",
      key: "5",
      answer:
        " If you have any more questions, feel free to reach us through           whatsapp, call, message or email . Our customer service is available          at all times of the day and will happily attend to your needs.",
    },
  ];
  return (
    <>
      <Helmet>
        <title>
          Pre-Book an all-inclusive chauffeur service in Peterborough
        </title>
        <meta
          name="description"
          content="Your Peterborough Private Chauffeur Service. Pre-Book Professional all-inclusive, reliable and efficient chauffeur services in Peterborough. Best Price Guaranteed."
        />
        <meta
          name="keywords"
          content="Chauffeur service in Peterborough, Chauffeur service from Peterborough,  chauffeur service from toronto to Peterborough, chauffeur service from Peterborough, chauffeur service to Peterborough, Chauffeur service, airport chauffeur Service, Peterborough chauffeur service, chauffeur service Peterborough, private chauffeur service Peterborough, A Chauffeur, Airport chauffeur service Peterborough, Chauffeur service near me, Airport Chauffeur, Private car service, Airport car service, airport limo Peterborough,  Shuttle service Peterborough, Hourly chauffeur service, Livery service"
        />
        <link rel="canonical" href="/city/chauffeur-service-peterborough" />
      </Helmet>
      <CityHero hero={hero} />
      <CityDesc desc={desc} />
      <MostBooked mostBookeds={mostBookeds} />
      <Stay stays={stays} />
      <Banner1 />
      <Faq faqs={faqs} />
    </>
  );
};

export default Peterborough;
