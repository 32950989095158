import React from "react";
import CityDesc from "../../components/web/cities/CityDesc";
import CityHero from "../../components/web/cities/CityHero";
import Stay from "../../components/web/Stay";
import MostBooked from "../../components/web/MostBooked";
import Faq from "../../components/web/Faq";
import Banner1 from "../../components/web/banners/Banner3";
import { Helmet } from "react-helmet-async";

// stay images
import one1 from "../../assets/images/hotels/cities/niagaraFalls/hilton-niagara-falls-fallsview-hotel-and-suites-chauffeur-service-niagara-falls-limo-services-car-service-near-niagara-falls.webp";
import one2 from "../../assets/images/hotels/cities/niagaraFalls/hilton-niagara-falls-fallsview-hotel-and-suites-private-chauffeur-service-niagara-falls-car-service-from-niagara-falls.webp";
import one3 from "../../assets/images/hotels/cities/niagaraFalls/airport-transfer-services-from-hilton-niagara-falls-fallsview-hotel-and-suites-niagara-falls-best-price-car-services-in-niagara-falls.webp";
import two1 from "../../assets/images/hotels/cities/niagaraFalls/limousine-services-from-embassy-suites-by-hilton-car-service-24-hours-in-niagara-falls-best-car-services-in-toronto.webp";
import two2 from "../../assets/images/hotels/cities/niagaraFalls/chauffeur-service-in-niagara-falls-to-embassy-suites-by-hilton-at-affordable-price-limo-car-service-downtown-niagara-falls.webp";
import two3 from "../../assets/images/hotels/cities/niagaraFalls/hourly-chauffeur-service-from-embassy-suites-by-hilton-niagara-falls-affordable-car-services-near-niagara-falls.webp";
import three1 from "../../assets/images/hotels/cities/niagaraFalls/car-service-from-niagara-falls-to-niagara-falls-marriott-fallsview-hotel-and-spa-all-the-way-limo-services-niagara-falls.webp";
import three2 from "../../assets/images/hotels/cities/niagaraFalls/executive-chauffeur-service-near-niagara-falls-marriott-fallsview-hotel-and-spa-comfortable-chauffeur-services-in-niagara-falls.webp";
import three3 from "../../assets/images/hotels/cities/niagaraFalls/budget-car-hire-niagara-falls-to-niagara-falls-marriott-fallsview-hotel-and-spa-affordable-price-luxury-service-limousines.webp";
import four1 from "../../assets/images/hotels/cities/niagaraFalls/car-hire-near-sheraton-fallsview-hotel-business-class-limo-services.webp";
import four2 from "../../assets/images/hotels/cities/niagaraFalls/all-inclusive-limo-services-near-sheraton-fallsview-hotel-limo-services-online-bookings.webp";
import four3 from "../../assets/images/hotels/cities/niagaraFalls/niagara-falls-limo-services-to-sheraton-fallsview-hotel-car-service-with-driver.webp";

const NiagaraFalls = () => {
  const hero = {
    title: "NiagaraFalls",
    description:
      "If you want a relaxing start to your journey, AChauffeur provides luxurious, hassle-free transportation to and from Niagara Falls city. With one of our professional chauffeurs, we guarantee your pleasant and secure journey.",
  };

  const desc = {
    title: "NiagaraFalls",
    description:
      "Niagara Falls is a city located in the Niagara Region of Ontario, Canada, about an hour's drive from Toronto. The city is named after the Niagara Falls, a group of three waterfalls that straddle the border between the United States and Canada. The Niagara Falls are one of the most popular tourist attractions in the world, known for their powerful and beautiful cascades of water. The Niagara Falls are located on the Niagara River, which flows from Lake Erie to Lake Ontario. In addition to the falls, the city of Niagara Falls is home to a number of other attractions, including casinos, theme parks, and wineries. The city is also known for its many parks and green spaces, as well as its rich history and cultural heritage.",
    website: "https://niagarafalls.ca/visitors/default.aspx",
    description2:
      "Achauffeur is a professional transportation service provider in Niagara Falls. we have a trained and licensed driver who drives a passenger in a luxury vehicle, such as a limousine. The chauffeur is responsible for navigating the vehicle to the desired destination, handling any necessary vehicle maintenance and repairs, and providing a high level of customer service to the passenger. we always focus on the safe and timely transportation of the passengers, as well as maintaining the cleanliness and upkeep of the vehicle. our services are often used for airport transfers, special events, business meetings, meet and greet, parcel delivery and etc, and can be hired on an hourly or daily basis via an online reservation system.",
  };
  const stays = [
    {
      image: [one1, one2, one3],
      alt: [
        "Book limo services from Niagara Falls to Hilton Niagara Falls/Fallsview Hotel & Suites best car services from achauffeur",
        "Private chauffeur service from Niagara Falls city to Hilton Niagara Falls/Fallsview Hotel & Suites. luxury car service near Niagara Falls",
        "Find airport transfer services in Niagara Falls to Hilton Niagara Falls/Fallsview Hotel & Suites at an affordable price and best services",
      ],

      title: "Hilton Niagara Falls / Fallsview ",
      location: "6361 Fallsview Blvd",
      distance: "900 m from city center",
      map: "https://maps.google.com/maps?q=Hilton%20Niagara%20Falls%20/%20Fallsview%20Hotel%20&t=&z=13&ie=UTF8&iwloc=&output=embed",
    },
    {
      image: [two1, two2, two3],
      alt: [
        "Professional limousine services from Embassy Suites by Hilton to Niagara Falls city. Best car services from achauffeur",
        "Safe and secure chauffeur services in Niagara Falls to Embassy Suites by Hilton. All-inclusive car services downtown Niagara Falls",
        "Hourly chauffeur service from achauffeur to Embassy Suites by Hilton from Niagara Falls canada at affordable price rates",
      ],
      title: "Embassy Suites by Hilton Niagara Falls",
      location: "6700 Fallsview Blvd",
      distance: "1.2 km from city center",
      map: "https://maps.google.com/maps?q=Embassy%20Suites%20by%20Hilton%20Niagara%20Falls%20Fallsview&t=&z=13&ie=UTF8&iwloc=&output=embed",
    },
    {
      image: [three1, three2, three3],
      alt: [
        "All the way limo services from Niagara Falls to  Marriott Fallsview Hotel & Spa. Achauffeur Minimize your Stress and Frustration",
        "Comfortable and executive chauffeur service to  Marriott Fallsview Hotel & Spa from Niagara Falls city. reliable  booking process from achauffeur",
        "achauffeur offers a budget car service in Niagara Falls. Hire budget cars and limos to  Marriott Fallsview Hotel & Spa. Luxury service available",
      ],
      title: "Niagara Falls Marriott Fallsview Hotel",
      location: "6740 Fallsview Blvd",
      distance: "1.3 km from city center",
      map: "https://maps.google.com/maps?q=43.078646250774504,%20-79.08201411812952&t=&z=13&ie=UTF8&iwloc=&output=embed",
    },
    {
      image: [four1, four2, four3],
      alt: [
        "Business class chauffeur services in Niagara Falls to Sheraton Fallsview Hotel. Luxury cars at affordable price",
        "Book your limousines online. All-inclusive limo services from Niagara Falls city to Sheraton Fallsview Hotel",
        "Do you need a car with driver? Achauffeur offers limo services with driver in Niagara Falls to Sheraton Fallsview Hotel",
      ],
      title: "Sheraton Fallsview Hotel",
      location: "5875 Falls Ave, Niagara Falls",
      distance: "1.2 km from city center",
      map: "https://maps.google.com/maps?q=Sheraton%20Fallsview%20Hotel&t=&z=13&ie=UTF8&iwloc=&output=embed",
    },
  ];
  const mostBookeds = [
    {
      start: "Niagara Falls",
      end: "Buffalo, New York, USA",
      duration: "28–40 min",
      distance: "37.5 km",
      price: "211.72",
    },
    {
      start: "Niagara Falls",
      end: "St. Catharines, Ontario",
      duration: "16–24 min",
      distance: "19.3 km",
      price: "211.72",
    },
    {
      start: "Niagara Falls",
      end: "Niagara-on-the-Lake, Ontario",
      duration: "22–28 min",
      distance: "24.1 km",
      price: "211.72",
    },
    {
      start: "Niagara Falls",
      end: "Hamilton, Ontario",
      duration: "35–45 min",
      distance: "60.2 km",
      price: "211.72",
    },
    {
      start: "Niagara Falls",
      end: "Toronto, Ontario",
      duration: "1 hr 20 min - 1 hr 50 min",
      distance: "128 km",
      price: "211.72",
    },
    {
      start: "Niagara Falls",
      end: "Brantford, Ontario",
      duration: "1 hr 10 min - 1 hr 30 min",
      distance: "113 km",
      price: "211.72",
    },
  ];
  const faqs = [
    {
      header: "Is my booking a shared shuttle or a private?",
      key: "1",
      answer: " All the bookings are private.",
    },
    {
      header: "How to book?",
      key: "2",
      answer:
        "You can make a reservation by filling the form on our website, giving          us a call at (888) 999-4545 or (416) 800-1009, or sending us an email.",
    },
    {
      header: "How can I contact my driver?",
      key: "3",
      answer:
        "If you need to contact your chauffeur, the best number to call will be          listed in the SMS alert while your booking confirmation. You can          always call us at (888) 999-4545 or (416) 800-1009.",
    },
    {
      header: "What factors influence the price?",
      key: "4",
      answer:
        "There are many factors that influence the cost, but the distance traveled and the type of vehicle are two of the major elements that affect the cost of travel. Depending on whether you are scheduling an hourly reservation or a one-way trip, rates are based on the distance and duration. Pickup time, Meet & Greet service at airports, and how far in advance the ride was scheduled are additional elements that affect our charges. The chauffeur's gratuity, any tolls, parking fees, baggage fees, additional guest fees, fares, taxes, and any other related costs are all included in all of our rates.",
    },
    {
      header: "  If I have more questions, who should I contact?",
      key: "5",
      answer:
        " If you have any more questions, feel free to reach us through           whatsapp, call, message or email . Our customer service is available          at all times of the day and will happily attend to your needs.",
    },
  ];
  return (
    <>
      <Helmet>
        <title>
          Pre-Book an all-inclusive chauffeur service in NiagaraFalls
        </title>
        <meta
          name="description"
          content="Your NiagaraFalls Private Chauffeur Service. Pre-Book Professional all-inclusive, reliable and efficient chauffeur services in NiagaraFalls. Best Price Guaranteed."
        />
        <meta
          name="keywords"
          content="Chauffeur service in niagara falls, chauffeur service from toronto to niagara falls, chauffeur service from niagara falls, chauffeur service to niagara falls, Chauffeur service, airport chauffeur Service, niagara falls chauffeur service, chauffeur service niagara falls, private chauffeur service niagara Falls, A Chauffeur, Airport chauffeur service Niagara falls, Chauffeur service near me , Private car service, Airport car service, airport limo, Shuttle service Hamilton, Hourly chauffeur service, Livery service  "
        />
        <link rel="canonical" href="/city/chauffeur-service-nigara-falls" />
      </Helmet>
      <CityHero hero={hero} />
      <CityDesc desc={desc} />
      <MostBooked mostBookeds={mostBookeds} />
      <Stay stays={stays} />
      <Banner1 />
      <Faq faqs={faqs} />
    </>
  );
};

export default NiagaraFalls;
