import React from "react";
import MostBookedCard from "./MostBookedCard";

const MostBooked = (props) => {
  return (
    <div className="px-3 py-12 sm:py-16 md:py-20 lg:py-24 bg-sec-100">
      <div className="container">
        <h2 className="text-center">
          Most booked chauffeur services from {props.mostBookeds[0].start}
        </h2>

        <div className="grid lg:grid-cols-2 gap-5 ">
          {props.mostBookeds.map((mostBooked) => (
            <MostBookedCard mostBooked={mostBooked} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default MostBooked;
